import logoGroove from "./img/logo-groove.jpg";
import img1 from '../dj/img/10.jpg';
import img2 from '../dj/img/11.jpg';
import img3 from '../dj/img/9.jpg';
import img4 from '../dj/img/8.jpg';
import img5 from '../dj/img/7.jpg';
import img6 from '../dj/img/galeria-dj-avanzado/dj-avanzado-1.JPG';
import img7 from '../dj/img/galeria-dj-avanzado/dj-avanzado-3.JPG';




export const ArrayInicio = [{logo: logoGroove},[img1, img2, img3, img4, img5, img6, img7]];